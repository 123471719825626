<template>
  <div>
    <div class="py-3 px-2">
      <v-text-field ref='search-input' data-cy-offers-filter outlined :placeholder="$t('product_page_filter_placeholder', {resource: title})" hide-details='always'
        v-model='querystring'
        v-on:keydown.enter='filter'
        :append-icon='loading ? "fa fa-spinner fa-spin" : "fa fa-search"'
        class="r---searchinput"
      >
      </v-text-field>
    </div>

    <!-- product loading -->
    <div v-if="!!loading" class="d-flex justify-center mt-8">
      <LoadingSpinner color='primary' class='mb-8'></LoadingSpinner>
    </div>

    <!-- product loaded -->
    <v-container v-else data-cy-offers-list class='overflow-y-auto'>
      <v-row v-if='results.length > 0' class="r---grid-row pt-3">
        <v-col v-for='offer in results' :key='offer.id' cols='6' class="r---grid-column">
          <OfferCard :offer='offer' />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/loading_spinner'
import OfferCard from '@/components/offer_card'

export default {
  components: {
    LoadingSpinner,
    OfferCard
  },

  data() {
    return {
      id: null,
      image_url: null,
      offers: [],
      title: null,
      loading: true,
      querystring: ''
    }
  },

  computed: {
    results() {

      return this.offers.filter((offer) => {
        return offer.sellable.name.toLowerCase().includes(this.querystring.toLowerCase())
      })
    }
  },

  mounted() {
    this.loading = true
    this.$store.dispatch('product/fetch_offers', this.$route.params)
      .then((response) => {
        this.id = response.id
        this.image_url = response.image_url
        this.$store.commit('offers/set_offers', response.offers)
        this.offers = response.offers
        this.title = response.name
        this.$store.commit('layout/page_nav', {title: response.name})
      })
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    filter(string) {
      this.offers = this.$store.getters['offers/list'].filter((offer) => {
        return offer.supplier.name.toLowerCase().includes(string.toLowerCase())
      })
    }
  }
}
</script>
